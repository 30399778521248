import { Identifier } from "react-admin";
import r from "../Utils/fetch";
import { BillAccountContract } from "./billingAccountContract";

const w: any = window;
const endPoint = `${w.API_BILL}`;


export const createContract = (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/${id}`, method: "POST", data: { id } });


export const createPdf= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/${id}/pdf`, method: "GET" });


export const sendToSign= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/${id}/sign`, method: "GET" });

