import React from 'react';
import { ReferenceManyField, Datagrid, TextField, FunctionField } from 'react-admin';
import { Card, CardContent, Typography, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface BillingAccountContractDocuments {
  record: any;
}

export const BillingAccountContractDocuments: React.FC<BillingAccountContractDocuments> = ({record}) => {
  
  const handleOpenFolder = () => {
    window.open(`https://drive.google.com/drive/folders/${record.folderId}`, "_blank");
  };

  const handleOpenDocument = (documentId: string) => {
    window.open(`https://docs.google.com/file/d/${documentId}`, "_blank");
  };


  return (

        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Documents
              </Typography> 
              <Button variant="contained" color="primary" onClick={handleOpenFolder}>
                    Open folder
              </Button>
            </div>
            <ReferenceManyField
                label="Documents"
                reference="billing-account-contract-documents"
                target="billingAccountContractId"
                sort={{ field: 'offerDetailContract.id', order: 'ASC' }}
              >
                <Datagrid>
                  <TextField source="name" label="Document" />
                  <FunctionField
                      render={(record) => (
                        <IconButton onClick={() => handleOpenDocument(record.id)} color="primary">
                          <EditIcon />
                        </IconButton>
                      )}
                    />
                </Datagrid>
              </ReferenceManyField>
           
          </CardContent>
        
        </Card>

)};

